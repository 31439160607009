////
/// This is a poster comment.
/// It will apply annotations to all items from file.
/// @group Font-Typography
////

/// font family Suisse del sito
$font:  Helvetica, Arial, sans-serif;

$font-base:16px;

/// font-size 9px
$font-9:  0.563rem;
/// font-size 10px
$font-10: 0.715rem;
/// font-size 11px
$font-11: 0.6875rem;
/// font-size 12px
$font-12: 0.75rem;
/// font-size 13px
$font-13: 0.857rem ;
/// font-size 14px
$font-14: 0.875rem ;
/// font-size 15px
$font-15: 0.938rem;
/// font-size 16px
$font-16: 1rem ;
/// font-size 17px
$font-17: 1.063rem;
/// font-size 18px
$font-18: 1.125rem;
/// font-size 19px
$font-19: 1.188rem;
/// font-size 20px
$font-20: 1.25rem;
/// font-size 22px
$font-22: 1.429rem;
/// font-size 24px
$font-24: 1.5rem ;
/// font-size 27px
$font-27: 1.688rem;
/// font-size 28px
$font-28: 1.750rem;
/// font-size 32px
$font-32 : 2rem;
/// font-size 36px
$font-36 : 2.25rem;

/// title h1 font size 32px
$font-h1: 2.25rem;
/// title h1 mobile font size 22px
$font-h1-mob: 1.429rem;

/// title h2 font size 27px
$font-h2: 1.71rem;

/// title h3 font size 18px
$font-h3: 1.14rem;

/// title h4 font size 16px
$font-h4: 1rem;

/// title h5 font size 13px
$font-h5: .857rem;

/// title h6 font size 13px
$font-h6: .75rem;


$font-title-small:1.14rem;

$font-p:$font-14;

$font-xs:$font-11; //11px


//font-weight
$font-light:  300;
$font-normal: 400;
$font-500:    500;
$font-600:    600;
$font-bold:   700;
$font-800:    800;
$font-bolder: 900;

//letter-spacing [TO-DO]
$lh-reset: 1;
$lh-double: 2;
$lh-standard: 1.2;
//da aggiungere e capire cosa ci serve



