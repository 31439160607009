////
/// This is a poster comment.
/// @group Griglia SA
////
@use '../variables' as *;

/* margin-bottom standard per i vari contenitori */

/// Wrapper a tutta pagina 
/// prende la dimensione totale della window
.wrapper--full {
  width: 100%;//100vw;
  margin-bottom: 60px;
}

.wrapper--middle,
.wrapper--content,
.wrapper--medium,
.wrapper--min {
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
  margin-bottom: 60px;
}

/// Wrapper middle:  dimensione massima <strong>1650px</strong>.
.wrapper--middle {
  max-width: 1650px;
}

/// Wrapper content: dimensione massima <strong>1440px</strong>.<br>
/// è il wrapper che definisce la massima larghezza dei contenuti.
.wrapper--content {
  max-width: 1440px;
}

/// Wrapper content: dimensione massima <strong>1100px</strong>.<br>
.wrapper--medium{
	max-width:1100px;
}

/// Wrapper min: dimensione massima <strong>900px</strong>.
.wrapper--min {
  max-width: 900px; //1100px;
}

.wrapper--middle:after,
.wrapper--content:after,
.wrapper--min:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

/* non useremo piu VERIFICARE  */
.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
  &:before, &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

///Wrapper slideshow dedicato per la  home page
.wrapper--slidehp{
  width: 100%;
  /*max-width:1650px;*/
  height: auto;
  min-height: 300px;
  //margin-bottom: 
  padding-bottom: 0;
  margin:0 auto 60px;
}




