////
/// This is a poster comment.
/// It will apply annotations to all items from file.
/// @group Mixin
////

@use 'colors' as *;
@use 'extra'   as *;
@use "sass:math";


/// Gestisce i vari breakpoint per le media queries<br>
/// <strong>minWidth</strong> -> max-width: 1440px <br>
/// <strong>landscape</strong> -> max-width: 990px<br>
/// <strong>tablet</strong> -> max-width: 768px<br>
/// <strong>mobile</strong> -> max-width: 500px<br>
/// @param {String} $point - description
@mixin breakpoint($point) { 
	
   @if $point == minWidth {
    @media (max-width: 1440px) {
      @content;
    }
  }@else if $point == landscape {
    @media (max-width: 900px) {
      @content;
    }
  }@else if $point == tablet {
    @media (max-width: 768px) {
      @content;
    }
  }@else if $point == mobile {
    @media (max-width: 500px) {
      @content;
    }
  }
}


//Mixin per freccia link
@mixin linkArrow($color: $c-black , $width:20, $border-w: 2, $diagonal-w: 1.3){	
	display: inline-block;
	position: relative;
	flex-shrink:0;
	width: #{$width}px;
	height: #{$width}px;
	border-right: #{$border-w}px solid $color;
	border-bottom: #{$border-w}px solid $color;
	background: linear-gradient(to top right, transparent 0%, transparent calc(50% - #{$diagonal-w}px), $color calc(50% - #{$diagonal-w}px), $color calc(50% - #{$diagonal-w}px), $color 50%, $color calc(50% + #{$diagonal-w}px), transparent calc(50% + #{$diagonal-w}px), transparent 100%);
}
	
	

@mixin saBoxSpace($attribute, $top: null, $right: null, $bottom: null, $left: null, $x: null, $y: null, $all: null) {
	@if $all{
		#{$attribute}: $all;
	}@else if $y and $x{
		#{$attribute}: $y $x;
	}@else if $y{
		#{$attribute}: $y 0;
	}@else if $x{
		#{$attribute}: 0 $x;
	}@else{
		#{$attribute}-top:    $top;
		#{$attribute}-right:  $right;
		#{$attribute}-bottom: $bottom;
		#{$attribute}-left:   $left;
	}  
}

/// Crea le classi predefinte per impostare i margini e i padding
/// @parameter {prefisso sa} $prefix [""] - Indica il tipo di prefisso ls- || pt- || mob-
/// @example scss - space mixin
///   space("ls-")
///   // lp-lsm20 {margin:20px}
///   // lp-lsp20 {padding:20px}
///   // lp-lsmt20 {margin-top:20px}
///   // lp-lspt20 {padding-top:20px}
@mixin space($prefix:""){
	// Utilities : Paddings, Margins
	$spaces: (0,10,15,20,25,30,50,40,60,70,80,90,100,120);
	$sides: (top, right, bottom, left);
	//per la landing usiamo solo top e bottom 
	//$sides: (top, right, bottom, left);
	
	// Margin Padding utility classes:
	// lp-p0: [padding: 0px]
	// lp-ml0: [margin-left: 0px]

	// lp-pt10 : [ padding-left: 10px ]
	// lp-mr20 : [ margin-right: 20px ]

	@each $space in $spaces {
		$px: "px";
		@if $space == 0 {
			$px: "";
		}
		.lp-#{$prefix}m#{$space} {
			margin: #{$space}#{$px} !important;
		}
		.lp-#{$prefix}p#{$space} {
			padding: #{$space}#{$px} !important;
		}
		
		//margin left - right
		.lp-#{$prefix}mlr#{$space} {
			margin-inline: #{$space}#{$px} !important;
		}
		//padding left - right
		.lp-#{$prefix}plr#{$space} {
			padding-inline: #{$space}#{$px} !important;
		}
		
		//margin top - bottom
		.lp-#{$prefix}mtb#{$space} {
			margin-block: #{$space}#{$px} !important;
		}
		//padding top - bottom
		.lp-#{$prefix}ptb#{$space} {
			padding-block: #{$space}#{$px} !important;
		}
		
		@each $side in $sides {
						
			.lp-#{$prefix}m#{str-slice($side, 0, 1)}#{$space} {
				margin-#{$side}: #{$space}#{$px} !important;
			}
			.lp-#{$prefix}p#{str-slice($side, 0, 1)}#{$space} {
				padding-#{$side}: #{$space}#{$px} !important;
			}
				
		}//end loop sides
	}//end loop space

}// end mixin


/// Helper per creare tutte le classi relative a margini e padding, anche per mobile.
/// @see {space} other-item
@mixin helperSpace{
	@include space;
	@include breakpoint(landscape){
		@include space("ls-");
	}
	@include breakpoint(tablet){
		@include space("pt-");
	}
	@include breakpoint(mobile){
		@include space("mob-");
	}
}

/// Helper per gestire Responsive Font Size
/// @parameter {Number} $minVal - valore minimo del font
/// @parameter {Number} $maxVal - valore massimo del font
/// @parameter {Number} $containerVal [1140] - Width del contenitore. di Default è 1140 ovvero la dimensione del contenitore dei testi
/// @parameter {String} $unit [px] - unità di misura di Default pixel
@mixin rfs($minVal,$maxVal,$containerVal:1140,$unit:px){
	$temp: math.div($containerVal, 100);
	$var:  math.div($maxVal, $temp);
	font-size: clamp(#{$minVal}px,#{$var}vw,#{$maxVal}px );
}


@mixin order($prefix,$from:12){
	@for $i from 1 through $from {
		.order#{$i}#{$prefix} {
			order:#{$i};
		}
	}
}


/// Mixin per la realizzazione dell'helper order
/// con il quale stabilire un ordinamento per gli
/// elementi di tipo flex o grid
@mixin helperOrder($from:12){
	@include order("",$from);
	@include breakpoint(landscape){
		@include order("-ls",$from);
	}
	@include breakpoint(tablet){
		@include order("-pt",$from);
	}
	@include breakpoint(mobile){
		@include order("-mob",$from);
	}
}

///Creazione di un'icona con cerchio più elemento interno
/// determinato da un attributo
/// di default avremo ! per cambiare elemento basta aggiungere inline  ad esempio style="--element:'x'"
/// ed al spoto di ! avremo la x
@mixin info($element:'!'){
	--element:'!';
	display:inline-flex;
	align-items: center;
	justify-content: center;
	width:24px;
	height:24px;
	color:$c-black;
	font-weight:700;
	border-radius:50%;
	border:2px solid $c-black;
	&:after{
		content:var(--element);
	}
}
	
	