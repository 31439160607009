////
/// classi helper per 
/// @group Helper SA
////
@use '../variables' as *;


//NASCONDI
.hide{
  display:none !important;
}	
// MOSTRA
.show{
  display:block !important;
}	
.show-f{
	display:flex;
}
@include breakpoint(landscape){
	.pt-hide{
	  display:none !important;
  }
	.ls-show{
  	display:block !important;
	}
	.ls-show-f{
  	display:flex !important;
	}
}
@include breakpoint(tablet){
	.pt-hide{
  	display:none !important;
	}
	.pt-show{
  	display:block !important;
  }
	.pt-show-f{
  	display:flex !important;
  }
}
@include breakpoint(mobile){
  .mob-hide{
  	display:none !important;
	}
	.mob-show{
  	display:block !important;
	}
	.mob-show-f{
  	display:flex !important;
	}
}

.border-block{
	border-block:1px solid $c-black;
}

.txt-center{
	text-align:center;
}
.txt-right{
	text-align:right;
}

.responsive-img {
  display: block;
  max-width: 100%;
}
.img-responsive {
  width: 100%;
  height: 100%;
  display: block;
}

.sa-info{
	@include info();
}

/** end helper **/