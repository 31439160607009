////
/// Grid layout 
/// @group Griglia 
/// sa-row indica il contenitore della griglia ed è costituito da un numero di colonne massimo di 12 nella versione desktop
/// e di 2 colonne nella versione mobile.<br>
/// La classe <b>sa-row</b> è usata per creare una griglia <code>display:grid</code> 
/// <h4>Versione Desktop</h4>
/// la griglia ha un massimo di <b>12</b> colonne 
/// @see %sa-row
/// @see $max-col-numbers
////
@use '../variables' as *;


/// Variabile che indica il numero massimo di colonne in versione desktop e landscape
$max-col-numbers: 12;
/// Variabile che indica il numero massimo di colonne in versione tablet [da 768px]
$max-col-numbers-pt : 6;
/// Variabile che indica il numero massimo di colonne in versione mobile [da 500px]
$max-col-numbers-mob : 2;
$col-3  : 3 ;
/// Distanza standard tra le colonne. Impostata a 20px;
$gap: 20px;

.sa-row{
  display:grid;
  grid-template-columns:repeat($max-col-numbers,1fr) ;
  gap: $gap;
  margin-bottom: $gap;
}

/// La classe <i>.cols-full</i> prende tutta la riga al completo ovvero 12 colonne<br>
/// Sostituendo full con un numero da <b>1</b> a <b>11</b> otteniamo il numero di colonne utilizzato per la dimensione del contenitore.<br>
/// La somma totale per riempire completamente una riga deve essere <b>12</b>
/// @example
/// <div class="sa-row">
///    <div class="col-4"></div> <div class="col-8"></div>
/// </div>
/// Questa struttura completa una riga
.sa-row > .cols-full { grid-column: 1 / -1}//span $max-col-numbers
.sa-row > .cols-1    { grid-column: span 1 }
.sa-row > .cols-2    { grid-column: span 2 }
.sa-row > .cols-3    { grid-column: span 3 }
.sa-row > .cols-4    { grid-column: span 4 }
.sa-row > .cols-5    { grid-column: span 5 }
.sa-row > .cols-6    { grid-column: span 6 }
.sa-row > .cols-7    { grid-column: span 7 }
.sa-row > .cols-8    { grid-column: span 8 }
.sa-row > .cols-9    { grid-column: span 9 }
.sa-row > .cols-10   { grid-column: span 10 }
.sa-row > .cols-11   { grid-column: span 11 }

/** Landscape **/
@media screen and (max-width: 900px) {
  .sa-row > .cols-ls-full { grid-column: 1 / -1}
  .sa-row > .cols-ls-half { grid-column: span 6}
  .sa-row > .cols-ls-1 { grid-column: span 1 }
  .sa-row > .cols-ls-2 { grid-column: span 2 }
  .sa-row > .cols-ls-3 { grid-column: span 3 }
  .sa-row > .cols-ls-4 { grid-column: span 4 }
  .sa-row > .cols-ls-5 { grid-column: span 5 }
  .sa-row > .cols-ls-6 { grid-column: span 6 }
  .sa-row > .cols-ls-7 { grid-column: span 7 }
  .sa-row > .cols-ls-8 { grid-column: span 8 }
  .sa-row > .cols-ls-9 { grid-column: span 9 }
  .sa-row > .cols-ls-10{ grid-column: span 10 }
  .sa-row > .cols-ls-11{ grid-column: span 11 }
}

/** Portrait **/
@media screen and (max-width: 768px) {
  .sa-row{ grid-template-columns:repeat($max-col-numbers-pt,1fr); }
  .sa-row > .cols-pt-full  { grid-column: 1 / -1}
  .sa-row > .cols-pt-half  { grid-column: span 3}
  .sa-row > .cols-pt-1 { grid-column: span 1 }
  .sa-row > .cols-pt-2 { grid-column: span 2 }
  .sa-row > .cols-pt-3 { grid-column: span 3 }
  .sa-row > .cols-pt-4 { grid-column: span 4 }
  .sa-row > .cols-pt-5 { grid-column: span 5 }
  .sa-row > .cols-pt-6 { grid-column: span 6 }
}

/** Mobile **/
@media screen and (max-width: 480px) {
  .sa-row{ grid-template-columns:repeat(calc($max-col-numbers-mob - 1),1fr); }
  .sa-row > .cols-mob-full  { grid-column: 1 / -1}
  .sa-row > .cols-mob-half  { grid-column: span 1}
}
