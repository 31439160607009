////
/// This is a poster comment.
/// It will apply annotations to all items from file.
/// @group Colori
////

/// Colore brand  - Blossomo primary
/// <br><svg width="10" height="10"><rect fill="#e96484" x="0" y="0" width="100%" height="100%"/></svg>
$c-primary-brand: #e96484;

/// Colore secondario SA
/// <br><svg width="10" height="10"><rect fill="#ACC3C9" x="0" y="0" width="100%" height="100%"/></svg>
$c-secondary-brand:#4c7b92;

$c-lightblu-secondary:#4c7b92; //#4c7b92

$c-lightblu:#abc3cb;

/// Colore Grayish orange SA
/// <br><svg width="10" height="10"><rect fill="#d5c5b6" x="0" y="0" width="100%" height="100%"/></svg>
$c-g-orange:#d5c5b6;


/// Colore nero SA
/// <br><svg width="10" height="10"><rect fill="#000" x="0" y="0" width="100%" height="100%"/></svg>
$c-black: #000;

/// Colore nero usato per il testo
/// <br><svg width="10" height="10"><rect fill="#231f20" x="0" y="0" width="100%" height="100%"/></svg>
$c-black: #231f20;

/// Colore brand 
/// <br><svg width="10" height="10"><rect fill="#e96484" x="0" y="0" width="100%" height="100%"/></svg>
$c-brand: $c-primary-brand;//#ff5740;

/// Colore brand  Business - Blu
/// <br><svg width="10" height="10"><rect fill="#006072" x="0" y="0" width="100%" height="100%"/></svg>
$c-brand-business: #006072;

/// Colore Bianco
/// <br><svg style="border:1px solid #000" width="10" height="10"><rect fill="#fff" x="0" y="0" width="100%" height="100%"/></svg>
$c-white: #fff;

/// Colore Grigio scuro
/// <br><svg width="10" height="10"><rect fill="#6d6e71" x="0" y="0" width="100%" height="100%"/></svg>
$c-graydark: #6d6e71;

/// Colore Grigio
/// <br><svg width="10" height="10"><rect fill="#aeaeae" x="0" y="0" width="100%" height="100%"/></svg>
$c-gray: #aeaeae;

/// Colore Grigio chiaro
/// <br><svg width="10" height="10"><rect fill="#e3e3e3" x="0" y="0" width="100%" height="100%"/></svg>
$c-graysoft: #e3e3e3;

$c-graylight: #f2f2f2;

/// Colore Placeholder input
/// <br><svg width="10" height="10"><rect fill="#c2c2c2" x="0" y="0" width="100%" height="100%"/></svg>
$c-placeholder: #c2c2c2;

/// Colore Testo product description
/// <br><svg width="10" height="10"><rect fill="#6d6e71" x="0" y="0" width="100%" height="100%"/></svg>
$c-text-description: $c-graydark; // colore testo product description

/// Colore Errore
/// <br><svg width="10" height="10"><rect fill="#DE3929" x="0" y="0" width="100%" height="100%"/></svg>
$c-error: #DE3929;

/// Colore Warning
/// <br><svg width="10" height="10"><rect fill="#FFBF00" x="0" y="0" width="100%" height="100%"/></svg>
$c-warning: #FFBF00;

/// Colore Success
/// <br><svg width="10" height="10"><rect fill="#4AD962" x="0" y="0" width="100%" height="100%"/></svg>
$c-success: #4AD962;