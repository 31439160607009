@import url('https://fonts.googleapis.com/css2?family=Domine:wght@400..700&display=swap');
@import url("https://use.typekit.net/wsd7qcs.css");

:root{
    --col-yellow: #FFD800;
    --col-yellow-text: #FFC300;
    --col-black: #181816;
    --col-fucsia: #FF0090;
    --col-white: #FFFFFF;
    --col-gray: #bfbfbf;
    --col-error: #ff3333;

    --txt-black:var(--col-black);
    --bck-white:#fff;

    --default-padding:20px;
    --padding-inline-mob:20px;
}

/*html{overflow-x: hidden;}*/
body{
    font-family: "museo-sans", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size:16px;
    /*overflow-x: hidden;*/
    &.menu-open{
        overflow-y: hidden;
        height: 100vh;
    }
}
h1,h2,h3,h4,h5,h6{font-family: "Domine", serif;}
h1{
    font-family: "Domine", serif;
    font-weight: 500;
    font-size:3.125rem;
}

p{
    font-size:1.0625rem;
    line-height:1.4;
    font-weight:500;
    color:inherit;
}
p:empty{display:none;}
p:has(br){}

strong{font-weight:900;}

.wrapper--full {
    container: wrapperFull / inline-size;
}

.fw300{font-weight: 300;}
.fw500{font-weight: 500;}
.fw700{font-weight: 700;}
.fw900{font-weight: 900;}
a:focus, a:active, a:visited, a:hover { color: var(--col-black); }

a.button,
button.button{
    position:relative;
    display:inline-block;
    color:inherit;
    padding:10px 60px;
    border:1px solid var(--col-black);
    border-bottom:2px solid var(--col-black);
    font-size:1rem;
    text-align: center;
    text-transform: uppercase;    
    & span{position:relative;z-index:1;}
    &.white{
        border:1px solid var(--col-white);
        border-bottom:2px solid var(--col-white);
    }
}
button.button{background:transparent;}

a.button::before,
button.button::before{
    --btn-black-bck: var(--col-black);
    --btn-white-bck: var(--col-white);
    content:"";
    position:absolute;
    width:100%;
    height:100%;
    top:0;
    left:0;
    background:var(--btn-black-bck);
    clip-path: inset(100% 0 0 0);
    transition:0.2s ease-out;
}
a.button.white::before{
    background:var(--btn-white-bck);
}
a.button:hover,
button.button:hover{color:var(--col-white)}
a.button.white:hover{color:var(--col-black)}
a.button:hover::before,
button.button:hover::before{
    clip-path: inset(0%);  
}
a.link{
    --pad-left:30px;
    position:relative;
    display: inline-block;
    padding:8px var(--pad-left) 8px 0;
    font-size:1rem;
    color:inherit;
    background-image: linear-gradient(var(--col-yellow) 0 0);
    background-size: 200% 10%; 
    background-position: 185% 100%;
    background-repeat: no-repeat;
    transition: all .3s;
    &::before{
        content:"";
        position:absolute;
        width:20px;
        height:20px;
        background: url('../img/arrow-right.svg') no-repeat;
        right: 0px;
        top: 30%;
    }
    &:hover {
        transition: all .3s;
        background-position: calc(100% - var(--pad-left)) 100%;
        padding-left:10px;
    }
}
a.button-whatsapp{
    display: flex;
    width: max-content;
    align-items: center;
    gap: 15px;
    padding: 7px 27px;
    border: 1px solid var(--col-black);
    border-radius: 25px;
    font-size: 14px;
    text-decoration: none !important;
    background: var(--col-black);
    color: #fff !important;
    margin-top: 15px;
}

a.back-link{
    position:relative;
    padding-left:30px;
    &::before{
        content:"";
        position:absolute;
        width:20px;
        height:20px;
        background: url('../img/arrow-left.svg') no-repeat;
        left:0px;
        top:15%;
    }
}

.logo{
    width:200px;
    margin-bottom:10px;
    &.matarans{
        width:110px;
    }
    &.dave{
        width:160px;
    }
    &-guitti-home{
        width:150px;
        margin-block-end:10px;
    }
    &-matarans-home{
        width: auto;
        height: 150px;
        margin-block-end:10px;
    }
    &-dave-home{
        width:120px;
        margin-block-end:10px;
    }
}


.summary{display:none;}
main {flex:1;}
main > .container, main > .container-fluid
{
    padding: 70px 15px 20px;
}

.bck{ /* old percent 86%, 25% */
    &-yellow{
        background:var(--col-yellow);
        background: linear-gradient(180deg, var(--col-yellow) 0%, var(--col-yellow) 92%, var(--col-black) 92%, var(--col-black) 100%);
    }
    &-yellow-f{
       background:var(--col-yellow);
    }
    &-yellow-w{
        background:var(--col-yellow);
        background: linear-gradient(180deg, var(--col-yellow) 0%, var(--col-yellow) 92%, var(--col-white) 92%, var(--col-white) 100%);
    }
    &-w-black{
        background:var(--col-black);
        background: linear-gradient(180deg, var(--col-black) 0%, var(--col-black) 92%, var(--col-white) 92%, var(--col-white) 100%);
    }
    &-black-w{
        background:var(--col-white);
        background: linear-gradient(180deg, var(--col-white) 0%, var(--col-white) 12%, var(--col-black) 12%, var(--col-black) 100%);
    }
    &-white{
        background:var(--col-white);
    }
    &-black{
        background:var(--col-black);
    }
    &-white-b{
        background:var(--col-white);
        background: linear-gradient(180deg, var(--col-white) 0%, var(--col-white) 85%, var(--col-black) 85%, var(--col-black) 100%);
    }

}

.bck-ls-black{
    @media (max-width: 900px) {
        background:var(--col-black);
    }
}
/* menu */
header{
    position: sticky;    
    width:100%;
    top:0;
    z-index:6;
    background: linear-gradient(90deg, var(--col-yellow) 0%, var(--col-yellow) 35%, rgb(255, 255, 255) 35%, rgb(255, 255, 255) 100%);
    &:has(.navbar .daveLogo){
        --col-yellow : var(--col-fucsia);
    }
}
.navbar {
    container: navbar / inline-size;
	z-index: 1000;
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	background-color: var(--bck-white );
	grid-template-rows: auto;
	grid-template-columns: .5fr 1fr 1fr 1fr;
	grid-auto-columns: 1fr;
	display: grid; 
	position: sticky;
	top: 0%;
	bottom: auto;
	left: 0%;
	right: 0%;
    padding:0;
    @media (min-width: 1980px) {
        max-width:1980px;
        margin:0 auto;
    }
    &-brand{
        background-color: var(--col-yellow);
        justify-content: center;
        align-items: center;
        width: fit-content;
        height: 60px;
        min-height: 100%;
        display: flex;
        position: relative;
        font-size:29px;
        font-family: "Domine", serif;
        font-weight:400;
        text-transform: uppercase;
        padding:20px 40px;
        z-index:7;
        &.daveLogo{ background-color: var(--col-fucsia);}
    }
}

/* menu hamburger */
.navbar-toggler{
    grid-area: 1/2/2/5;
    justify-self: end;
    margin-right:40px;
    z-index:7;
}
/* hamburger-active */
.hamburger {
    grid-area: 1/2/2/5;
    justify-self: end;
    margin-right:40px;
    z-index:7;
    position: absolute;
    height: 40px;
    width: fit-content;
    padding-left:40px;
    background-color: transparent;
    border: none;
    transition: all 0.2s ease-in-out;
    font-weight:700;
    color:var(--col-black);
    @container navbar (max-inline-size: 480px) {
        margin-right:15px;
        font-size:14px;
    }
    & span {
        position: absolute;
        left: 5%;
        top: 46%;
        width: 25px;
        height: 4px;
        background-color: black;
        border-radius:10px;
        transition: all 400ms cubic-bezier(.84,.06,.52,1.8);
        &:first-of-type{
            transform: translateY(-8px);
            animation-delay: 100ms;
            height: 4px;
        }
        &:last-of-type{
            transform: translateY(8px);
            animation-delay: 250ms;
            height: 4px;
        }
    }
    &-active span:first-of-type{transform: rotate(40deg);}
    &-active span:nth-child(2){opacity: 0;}
    &-active span:last-of-type{transform: rotate(-40deg);}
    
}

@container navbar (max-inline-size: 900px) { 
    .navbar-brand {
        font-size:clamp(18px,3.8vw,29px);
        padding-inline:8px;
    }
    .navbar-toggler{margin-right: 10px;}
}
/* Menu fullpage right to left */
.menu-fullpage{
    position:fixed;
    visibility: hidden;
    top:0;
    /*left:100%;*/
    z-index:5;
    width:100dvw;
    height:100dvh;
    background:var(--col-white);
    transform: translate3d(-100%, 0, 0);
    transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1);
    padding:130px;
    overflow-y: auto;

    @media(max-width:900px) {
        padding-inline: 40px;
    }
    &-wrapper{
        max-width:1980px;
        margin:0 auto;
    }
    &-active{
        /*transform: translate3d(-100%, 0, 0);*/
        transform: translate3d(0, 0, 0);
        visibility: visible;
    }
    
    & .title-menu{
        font-size:1.125rem;
        color: #ADADAD;
        margin-block-end: 10px;
        &.topmenu{
            @media(min-width:1980px) {
                font-size:1.3rem;
            }  
        }
    }


    & .menu-sede{
        margin-top:80px; 
        @media(max-width:600px) {
            margin-top:40px;
        }       
        p{
           font-size:1rem; 
           line-height:1.6;
        }
        a {
            color:var(--col-black);
            font-weight:700;    
        }
    }
    & .menu-company{
        @media(max-width:600px) {
            margin-top:40px;
        }    
        & li:not(:last-of-type){
            margin-block-end: 20px;
        }
        & a{
            font-family: "Domine", serif;
            font-size:1.6875rem ;
            font-weight:500;
            color:var(--col-black);
            @media(min-width:1980px) {
                font-size:2.3rem;
            }            
        }        
    }
}
/* listato menu principale */
#menu{
    display: block;
    margin-right:100px;
    counter-reset: menu; /* Set a counter named 'menu', and its initial value is 0. */
    & .nav-item{
        display: flex;
        align-items: end;   
        @media(min-width:1980px) {
            align-items: center; 
        }     
        &::before {  
            counter-increment: menu; /* Increment the value of section counter by 1 */      
            content: "0" counter(menu) "."; 
            font-family: "Domine", serif;
            font-size:1.125rem;
            font-weight:900;
            color:var(--col-yellow-text);
            margin-block-end:2px;
            @media(min-width:1980px) {
                font-size:1.4rem;
            }
        }
        &:not(:last-of-type){
            margin-block-end: 28px;
            @media(max-width:500px) {
                margin-block-end: 15px;                   
            }
        }
    }
    & .nav-link{
        font-family: "museo-sans", sans-serif;
        font-size:1.6875rem;
        text-transform: capitalize;
        color:var(--txt-black);
        padding:0 1rem;
        font-weight:500;        
        &.active{color:var(--col-yellow-text);}
        &:hover{
            color:var(--col-yellow-text);
        }
        @media(min-width:1980px) {
            font-size:2.5rem;
        }
    }
}
/* colori brand */
.showing-logo{
    background:var(--col-black);
    color:#fff;
    &:hover{
        color:#fff;
    }
}
/* END MENU */

/* Footer */
footer{
    width:100%;
    max-width:1600px;
    margin:0 auto;
    margin-top:100px;
    border-top:2px solid var(--col-yellow);
    padding:60px;
    @media(max-width:1200px) {
        padding-inline:var(--padding-inline-mob)
    }
    & p{
        font-size:1rem;
        line-height:1.4;
    }
    
    & p.title{
        font-family: "museo-sans", sans-serif;
        font-weight:900;
        font-size:1.125rem;
        margin-bottom:10px;
    }
    & a{
        display: inline-block;
        color:var(--col-black);
        text-decoration:underline;
        margin-top:8px;        
        &.foot-link{
            text-decoration:none;
            font-weight:500;
            font-size:1rem;
        }
        &:hover{text-decoration:underline;}
    }
}

.img-full{
    width:100%;
    height:auto;
    aspect-ratio: 4/3;
}

/* Home Page */
.hp-company{
    & img{        
        border-top:60px solid var(--col-black);
        border-left:60px solid var(--col-black);
    }
}
.hp-guitti{
    color:#fff;
}

.hp-matarans{
     & img{        
        border-top:60px solid var(--col-white);
        border-left:60px solid var(--col-white);
    }
}

.hp-event{
    max-width:1350px;
    width:100%;
    margin:80px auto 35px;
    padding-inline:var(--padding-inline-mob);
    & h2{
        font-family: "museo-sans", sans-serif;
    }
}
.hp-showing{
    img{
        max-width:380px;
        margin-right: 100px;
        @media(max-width:900px) {
            margin:0;        
        }    
    }
    a.link{margin-left:20px;}
    &-generic{
        & > span{color:var(--col-black) !important }
        & h2{
            font-size:3.75rem;
            @media(max-width:600px) {
                font-size: clamp(2.5rem, 6.666vw,3.75rem)   
            }
        }
        &.text{
            max-width:540px;
        }
    }
    &-name{
        text-transform: capitalize;
        color:var(--col-yellow-text);
        font-size: 3.25rem;
        margin-left: 20px;
        margin-top:-35px;
        @media(max-width:768px) {
            font-size: clamp(16px, 10.41666vw,80px)
        }
        @media(max-width:468px) {
            margin-top:0px;
        }
    }

}

.test-sub{
    height:190px;
    background:#000;
}

.text{
    width:fit-content; 
    max-width:768px;
    padding-inline: var(--padding-inline-mob);
    font-size:1.0625em;
    line-height:1.2;
    & > span{
        font-family: "museo-sans", sans-serif;
        font-size:1rem;
        font-weight:700;
        color:var(--col-yellow-text);
    }
    & p{
        margin-block:10px 40px;
    }
    &-white{
        color:var(--col-white);
        & a.link::before{
            content:"";
            background: url('../img/arrow-right-w.svg') no-repeat;
        }
    }
}
@container wrapperFull (max-inline-size: 1400px) {
    .text{ 
        padding-inline: var(--padding-inline-mob);
    }
}
@container wrapperFull (max-inline-size: 900px) {
    .text{ 
        max-width:100dvw;
        padding-inline: var(--padding-inline-mob);
        margin-inline:0;
    }
    .hp-company{
        padding-inline: var(--padding-inline-mob);
        & img{border:none;}
    }
    .hp-guitti{
        order: 2;
        & img{border:none;}
    }
    .hp-matarans,
    .hp-dave{
        & img{border:none;}
    }
    .hp-showing{
        padding-inline:var(--padding-inline-mob)
    }
}

/* COMPAGNIA */
.company{
    &-block-members{
        width:100%;
        max-width:900px;
        margin:0 auto;
        margin-block-start:140px;
        text-align:center;
        color:var(--black);
        & h2{
            font-weight:400;
            font-size:3.75rem;
        }
        & > span {
            font-family: "museo-sans", sans-serif;
            font-size: 1rem;
            font-weight: 700;
            color: var(--col-yellow-text);
            margin-block-end:8px;
        }
        @media(max-width:900px) {
            padding-inline: var(--padding-inline-mob);
        }
    }
    &-list{
        grid-column-gap: 150px;
        grid-row-gap: 16px;
        grid-template-rows: auto;
        grid-template-columns: 0.5fr 0.5fr;
        grid-auto-columns: 1fr;
        grid-auto-flow: row;
        justify-items: center;
        max-width: 1100px;
        margin-left: auto;
        margin-right: auto;
        padding-inline:var(--padding-inline-mob);
        padding-bottom: 200px;
        display: grid;
        @media(max-width:900px) {
            grid-template-columns: 1fr;;
        }
        &-preview{
            & .summary{display:none;}
            margin-bottom:140px;
            &:nth-child(2n) {
                position: relative;
                top: 50%;
                 @media(max-width:900px) {
                    top:0;
                 }
            }
            &:nth-last-child(1){margin-bottom:0}
            & img{
                height:auto;
                aspect-ratio:unset;
            }
            & h3 {
                font-size: 3.25rem;
                color: var(--col-yellow-text);
                margin-block-start: -30px;
                margin-inline-start: 20px;
                line-height: 1;
            }
            & p{
                color: var(--col-black);
                margin-inline-start: 20px;
                margin-block: 15px;
                font-size:1.125rem;
                text-transform: uppercase;
            }
             & a.link{
                margin-inline-start:20px;
             }
        }
    }
}
#membersGallery{
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    visibility: visible;
}
.list-view-members{
    container: memberDetail / inline-size;
    flex-wrap: wrap; 
    @media(max-width:900px) {
         flex-wrap: nowrap; 
    }
    & .summary{display:none;}
    & .member-detail{   
        flex: 0 1 33.333%; 
        width:33.3333%;    
        display: flex;
        flex-direction: column;
        align-items: start;
        padding:50px;
        @media(max-width:900px) {
            flex:0 0 auto;
        }
        & h3{
            font-size:clamp(22px,4.166vw, 3.75rem);   
            color:var(--col-yellow-text);
            margin-block-start:-40px;
            margin-inline-start: 20px;
            line-height:1;
        }  
        & .link{margin-inline-start: 20px;margin-block-start: 30px;}   
    }
}
.splide__pagination__page.is-active{background:var(--col-black)}
.splide.is-initialized:not(.is-active) .splide__list {
  display: flex;
}
/* SOCI */
.member{
    &-back{
        grid-column: span 2;
        padding-block:80px;
        justify-self: end;
        a {
            color:var(--col-black)
        }
        padding-inline: var(--padding-inline-mob);
        @media(max-width:900px) {
            grid-column: 1;
            padding-block:30px;
        }
    }    
    &-view{
        width:100%;
        max-width:1500px;
        display:grid;
        grid-template-columns: auto ;
        column-gap: 80px;
        margin:0 auto;
        padding-inline: var(--padding-inline-mob);
        aside{
            justify-self: center;
        }
        @media(max-width:900px) {
            grid-template-columns: 1fr;
            column-gap: 0px;
            aside{
                justify-self: start;
            }
        }
    }
    &-text{
        h1{
            font-size:3.75rem;
            width: min-content;
            @media(max-width:768px) {
                margin-block-start:15px;
                width: fit-content;
                font-size: clamp(2.5rem ,7.81vw ,3.75rem); 
            }
        }
        .role{
            font-weight:700;
            text-transform: uppercase;
            font-size:2.18rem;
            color:var(--col-yellow-text);
            line-height: 1.4;;
            margin-block: 20px 50px;
            & span{
                display: block;
                font-size:1.5rem;
            }
        }
    }
}
#dave .bck-yellow{
    --col-yellow: var(--col-fucsia);
}
#matarans .bck-yellow{
    --col-yellow: var(--col-white);
}

@container memberDetail (max-inline-size: 900px) {
    .list-view-members .member-detail{
         & h3{
            font-size:clamp(28px, 3.888vw , 3.75rem);
            margin-block-start:10px;
         }
    }
}
@container memberDetail (max-inline-size: 500px) {
    .list-view-members .member-detail{
        padding-inline:20px;
        & h3{
            margin-block-start: 20px;
            margin-inline-start: 0;
        }
        & .link {
            margin-inline-start: 0;
            margin-block-start: 30px;
        }
    }
}

.support{
    container: support / inline-size;
    height: 500px;
    background: var(--col-black);
    padding:1px;/* prevent collapsing */
    @media(max-width:900px) {
        background: var(--col-yellow);
        height: auto;
    }
    &-text{
        height:110%;
        width:100%;
        max-width:1100px;
        padding:80px;
        background: var(--col-yellow);
        margin:0 auto;
        margin-top:-1.3%;
        @media(max-width:900px) {
            padding-inline:20px;
        }
        & h3{
            font-size:3.75rem;
            width: 70%;
            text-align: center;
            @media(max-width:900px) {
                font-size:clamp(20px,6.666vw,3.75rem); 
                width: 100%;
            }
        }
        & p {text-align:center}
    }
}

/* EVENTI */
.event-page-title{
    width:100%;
    max-width:1350px;
    margin:0 auto;
    margin-block:60px;
    & h2{
        font-size:2.5rem;
        padding-inline-start: 40px;
        font-weight:400;
    }
}

.event-nav{
    display:flex;
    max-width: 1350px;
    margin-left: auto;
    margin-right: auto;
    margin-block-start: 140px;
    &-item{
        flex:0 1 33.3333%;
        text-align:center;      
       @media(max-width:500px) {
            flex:1 1 auto;
        } 
    }
    & .nav-link{
        width:100%;
        height:100%;           
        padding-block:25px;       
        border-bottom: 1px solid var(--col-black);
        font-size:2rem;
        font-weight:700;
        @media(max-width:1600px) {
            font-size:clamp(0.75rem,2vw,2rem);
        }
        &.active{
           border-bottom: 3px solid var(--col-yellow); 
        }
    }
}


:nth-last-child(1 of .event-wrapper) .event-container{
    border-bottom:1px solid var(--col-black);
}
.event-wrapper{
    width:100%;
    
    & > a{
        display:block;
        width:100%;
        height:100%;
        transition: all .2s;
        display: flex;
        position: relative;        
        padding-inline:50px;
        color:var(--col-black);        
        @media(max-width:900px) {
            padding-inline:20px;
        }
        &:hover{
            background: rgba(255, 216, 0,.2);
        }
    }  
}
.event-container{
    width:100%;
    max-width:1350px;
    margin:0 auto;
    border-top:1px solid var(--col-black);
    padding-block:80px;
    flex-wrap:wrap;    
    @media(max-width:600px) {
        padding-block:40px;
    }
    & .times {
        flex-wrap: wrap;
        flex: none;
        align-self: flex-start;
        flex-direction:column;
        width: 15%; /*33.333%;*/
        padding-left: 3px;
        padding-right: 3px;
        display: flex;
        font-size:1.3125rem;
        @media(max-width:900px) {
            width:50%;
        }
        @media(max-width:600px) {
            width:100%;
        }
        &-data {
            font-weight:700;
            margin-bottom:10px;        
        }
    }
    & .type {
        flex: 1 0 auto;
        max-width:40%;
        @media(max-width:900px) {
            order:3;
            width:100%;
            max-width: none;
            margin-top:30px;
        }
        @media(max-width:600px) {
            text-align:left;
        }
        & span{
            font-family: "museo-sans", sans-serif;
            font-weight:700;
            font-size:1.125rem;
            text-transform: uppercase;
        }
        & h2{
            font-size:2.5rem;
            font-weight:400;
            text-align:left;
        }
    }
    & .place {
        flex-wrap: wrap;
        flex: none;
        flex-direction:column;
        padding-left: 3px;
        padding-right: 3px;
        display: flex;
        width:25%; /*33.333%;*/
        @media(max-width:900px) {
            order:2;
            width:50%;
        }
        @media(max-width:600px) {
            width:100%;
            margin-top: 10px;
            align-items: flex-start;
        }
        & p{
            font-size:1.3125rem;
        }
    }
} 
.event-view{
    max-width:1350px;
    width:100%;
    margin:0 auto;
    padding-inline: var(--default-padding);
    .event-back{
        padding-block:70px;
        a {
            color:var(--col-black)
        }
    }   
    & h1{
        font-size:2.8125rem;
        @media(max-width:768px) {
            font-size:clamp(1.875rem,5.85vw,2.8125rem);
        }
        span{
            display: block;
            font-family: "museo-sans", sans-serif;
            font-size:2.1875rem;
            font-weight:700;
            text-transform: uppercase;
            color:var(--col-yellow-text);
            @media(max-width:768px) {
                font-size:clamp(1.5rem,4.555vw,2.1875rem);
            }
        }
    }
    & h2{
        font-family: "museo-sans", sans-serif;
        font-size: 1.625rem;
        font-weight:700;
        text-transform: uppercase;
        margin-block-end:30px;
        @media(max-width:768px) {
            font-size: 1.375rem;
        }
    }
    & .detail,
    & .description{
        border-top:1px solid var(--col-black);
        @media(max-width:768px) {
            padding-inline: 0 !important;
        }
    }
    
    .description-img{
        margin-block-start:20px;           
        & img{max-width:367px;width:100%;}
    }
}

/* SPETTACOLI */
.showing-header{
    & img{        
        border-top:60px solid var(--col-white);
        border-left:60px solid var(--col-white);
        @media(max-width:900px) {
            border:none;
        }
    }
}

.billboard{
    width:100%;
    height:0;
    max-width:1100px;
    margin:0 auto;
    margin-block-start: 180px;
    display: flex;
    justify-content: flex-end;
    @media(max-width:900px) {
        height:auto;
        margin-block: 0 90px;
        justify-content:center;
        width:100%;
    }
    & h3{
         font-size: 3.75rem;
    }
    &-text{
        margin-top:20px;
        flex:0 1 50%;
        padding-inline:75px;
        @media(max-width:900px) {
            flex-basis:100%;
        }
        @media(max-width:768px) {
            padding-inline:var(--padding-inline-mob);
        }
        & h2{font-size:3.4rem;
            @media(max-width:768px) {
                font-size: clamp(2.5rem, 6.666vw,3.75rem)   
            }
        }
        & p{ font-size:1rem;}
    }
}
.showing-index{
    grid-column-gap: 150px; 
    grid-row-gap: 16px;
    grid-template-rows: auto;
    grid-template-columns: .5fr .5fr;
    grid-auto-columns: 1fr;
    grid-auto-flow: row;
    justify-items: center;
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 20px;
    display: grid;
    @media(max-width:900px) {
        padding-inline: var(--padding-inline-mob);
        grid-template-columns: 1fr;
    }
}
.showing-preview{
    & strong{font-weight:700;}
    margin-bottom:140px;    
    &:nth-last-child(1){
        margin-bottom:0;
        @media(max-width:600px) {
            margin-bottom:40px;
        }
    }     
    &:nth-child(2n){ 
        position:relative;
        top:40%;
        @media(max-width:900px) {
           top:0%; 
        }
        &:last-of-type{
            top: 0%;
            margin-top: 80%;
            @media(max-width:900px) {
                margin-top:0%; 
            }
        }
    }

    & a{
        color:var(--col-black);
    }
    & img{
        max-width:380px;
        height:auto;
        aspect-ratio:unset;
    }
    & h3 {
        font-size: 3.25rem;
        color: var(--col-yellow-text);
        margin-block-start: -30px;
        margin-inline-start: 20px;
        line-height: 1;
    }
    & .subtitle{
        margin-block:20px;
        margin-inline-start: 20px;
    }
    & a.link{
        margin-inline-start: 20px;
    }
    
}
/* dettaglio spettacolo */
.showing-detail{
    & p{margin:0;}
    & h1{
        font-size:3.4rem;
        font-weight:500;
        color:var(--col-yellow-text);
        text-transform: capitalize;
    }
    &-subtitle {
        margin-bottom:30px;
        & strong:nth-child(1){
            color:var(--col-yellow-text);
            font-weight:700;
        }
        & p{
            font-weight:700;        
        }
    }   
    & a.button{
        --btn-black-bck: var(--col-black);
        --btn-white-bck: var(--col-white);

        color:var(--col-yellow-text);
        border-color:var(--col-yellow-text);
        &::before{
            --btn-black-bck: var(--col-yellow);
        }
        &:hover{color:var(--col-black)}
    } 
    
}
/* vista singolo spettacolo  */
.showing-view{
    .block-txt{
        width: fit-content;
        max-width: 768px;/* 540px */
        padding-inline:var(--padding-inline-mob);
        line-height:1.5;
        position: relative;
        @media(max-width:900px) {
            padding-inline:var(--padding-inline-mob);
            max-width: none;
        }
        h2{
          font-family: "museo-sans", sans-serif;
          font-size:1.3125rem; 
          font-weight:900; 
          margin-block-end:30px ;
        }
        p{
            margin-block-end:10px;
        }
    }
    .video-text{
        h3 { 
            font-size:3.125rem;
            @media (max-width: 900px) {
                font-size: clamp(1.875rem, 5.55vw, 3.125rem);
            }
        }         
    }
    
    .wrapper-video{
        #player{
            aspect-ratio: 16/9;
            width:100%;
            max-width: 960px;
            height: auto;
        }
    }
    
    .block-host-showing{
       max-width:900px;        
       padding-inline:var(--padding-inline-mob);
       & h3{
            font-family: "museo-sans", sans-serif;
            font-size:3.125rem; 
            font-weight:500; 
            text-align:center;
            @media(max-width:900px) {
                font-size:clamp(1.875rem,5.55vw ,3.125rem); 
            }
       }
       & p{
            text-align:center;
       }
       & a.button{
            border-color:var(--col-white);
            &::before{
                --btn-black-bck: var(--col-white);
            }
            &:hover{color:var(--col-black)}
        }
    }
}
/*PARTNERS*/
.partners-title{
    padding-inline:var(--padding-inline-mob);
    & h3{
        font-family: "museo-sans", sans-serif;
        font-size: 1.3125rem;
        font-weight:500;
        text-align:center;
        text-transform: uppercase;
        margin-block:60px;
    }
}
.wrapper-partners{
    width:100%;
    max-width:1350px;
    margin:0 auto;
    padding-inline:var(--padding-inline-mob);
    & .splide__pagination{
        bottom:-30px;
    }
    & .img-full{
        aspect-ratio: unset;
    }
}

/* CONTATTI */
.contact-text{
    width:100%;
    max-width:1400px;
    margin:0 auto;
    padding-inline: var(--default-padding);
    & h1{
        font-size:3.4375rem;
        font-weight:500;
    }
    & .contact-detail{
        padding-left: 70px;
        @media(max-width:600px) {
            padding-left: 0px;        
        }
        & h2{
            font-family: "museo-sans", sans-serif;
            font-weight:900;
            font-size: 1.875rem;
            margin-bottom:15px;
        }
        & p{
            font-size: 1.3125rem;
            line-height: 1.5;
            @media(max-width:600px) {
                font-size:clamp(16px,4vw ,24px);
            }
        }
        & a{
            color:var(--black);
            text-decoration:underline;
        }
    }
}
.contact-form{
    width:100%;
    max-width:1400px;
    margin:0 auto;
    padding-inline: var(--default-padding);
    margin-block-end:60px;
    .formTitle{
        width:400px;
        font-size:2.5rem;
        @media(max-width:768px) {
            width:100%;
            font-size:1.375rem;
        }
    }
    & form{
        padding-inline: 70px;
        max-width: 1200px;
        margin: 0 auto;
        @media(max-width:600px) {
            padding-inline: 0px;        
        }
    }
    .form-control{
        border:none;
        border-radius:0;
        border-bottom:2px solid #4d4d4b ;
        &:focus {
            box-shadow:none;
        }
    }
    select.form-control{
        background: url('../img/arrow-down.svg') 98% center no-repeat;
    }
    & div[class*='field-contactform']{
        display:flex;
        flex-direction: column;
        & label{
            order:2;
            margin-top:15px;
            padding-inline-start:20px ;
            color:#afafaf;
            font-size:1.125rem;
        }
    }
    .form-check-label{margin-top: 0 !important;}
    #contactform-privacy {
        appearance: none;
    }
    .form-group.prv label {
        position: relative;
        font-size: 13px;
        padding-inline-start: 30px !important;
        line-height: 1.5;
        text-align: left;
        color:var(--col-black) !important;
        font-size:16px !important;
        & a {
            color:var(--col-black);
            text-decoration: underline;
        }
    }
    .form-group.prv label::after {
        content: "";
        position: absolute;
        left: 3px;
        top: 9px;
        display: none;
        height: 7px;
        width: 11px;
        border-left: 2px solid var(--col-black);
        border-bottom: 2px solid var(--col-black);
        transform: rotate(-45deg);
    }
    .form-group.prv label::before {
        width: 18px;
        height: 18px;
        border-color: #231f20;
        border-radius: 4px;
        cursor: pointer;
        content: "";
        display: inline-block;
        position: absolute;
        top: 5px;
        left: 0;
        border: 1px solid rgba(0,0,0,.15);
        background-color: #fff;
        transition: border .15s ease-in-out,color .15s ease-in-out;
    }
    .form-group.prv input[type="checkbox"]:checked + label::after {
        display:block;
    }    
    .help-block{
        color:var(--col-error);
    }
}
/* FINE CONTATTI */

/* MEDIA */
.media{
    padding-inline: 20px;
    &-header{
        margin-top:80px;
        z-index:1;
        position: relative;
        background:#fff;
        & h1{
            font-family: "museo-sans", sans-serif;
            text-align:center;
        }
    }
    &-text{
        width: 100%;
        max-width: 1440px;
        margin: 0 auto;
        margin-block-start: 20px;
    }
    &-search{
        width:100%;
        max-width:1440px;
        margin:0 auto;
        padding-block:10px;        
        margin-block:60px;
        form{
            display:flex;
            gap:30px;
            width:fit-content;
            width: max-content;
            margin: 0 auto;
            @media(max-width:768px) {
                flex-wrap:wrap;
                flex-basis:50%;
            }
            @media(max-width:500px) {
                .form-group:not(:nth-last-child(1)){
                    width:100%;
                }
            }
            & .kv-date-picker{
                display:none;
            }
            .kv-dp-icon:before{
                content:"x";
                font-weight:bold;
            }
            #mediasearch-id_company{min-width:230px;}
            select,
            #mediasearch-year{
                background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16"> <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/></svg>');
                background-repeat: no-repeat;
                background-position: 95% center; 
            }
            .btn-primary{
                padding-inline-start:30px;
                --bs-btn-color: var(--col-black);
                --bs-btn-bg: var(--col-yellow);
                --bs-btn-border-color: var(--col-yellow);
                --bs-btn-hover-color: var(--col-black);
                --bs-btn-hover-bg: var(--col-yellow);
                --bs-btn-hover-border-color: var(--col-yellow);
                --bs-btn-focus-shadow-rgb: 49,132,253;
                --bs-btn-active-color: var(--col-black);
                --bs-btn-active-bg: var(--col-yellow);
                --bs-btn-active-border-color: var(--col-yellow);
                --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
                --bs-btn-disabled-color: var(--col-black);
                --bs-btn-disabled-bg: var(--col-yellow);
                --bs-btn-disabled-border-color: var(--col-yellow);
                background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/></svg>');
                background-repeat: no-repeat;
                background-position: 15% center; 
            }
        }
    }
    &-gallery{
        width:100%;
        max-width:1440px;
        margin:0 auto;
        padding-inline:var(--padding-inline-mob);
        display:grid;
        grid-template-columns: repeat(4, 1fr);
        gap:20px;
        @media(max-width:900px) {
            grid-template-columns: repeat(4, 1fr);
        }
        @media(max-width:768px) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media(max-width:500px) {
            grid-template-columns: 1fr;
            row-gap: 60px;
        }
    }
    &-detail{
        h3{
            font-family: "museo-sans", sans-serif;
            font-size:1.625rem;
            text-transform: capitalize;
            color:var(--col-yellow-text);
            /*margin-top:-20px;*/
        }
    }    
}
/* FINE MEDIA */
/* PULSANTE TORNA SU */
.top {
    display:flex;
    z-index: 4;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 40px;
    height: 40px;
    bottom: 15px;
    right: 15px;
    padding: 10px;
    font-family: sans-serif;
    background: var(--green);
    border-radius: 10px;
    white-space: nowrap;
    opacity: 0;
    transform: translateY(100px);
    transition: .5s;
    background: var(--col-yellow);
    border:2px solid var(--col-black);
    @media(min-width:1980px) {
        right:calc((100vw - 1965px ) / 2 );
    }
    &.showBtn {
        opacity: 1;
        transform: translateY(0);
    }
    & i {
        width: 10px;
        height: 10px;
        display: block;
        border-top: 3px solid var(--col-black);
        border-right: 3px solid var(--col-black);
        transform: rotate(-45deg);
        top: 20%;
        position: relative;
    }   
}



/* yii base */

.grid-view th,
.grid-view td:last-child {
    white-space: nowrap;
}

.grid-view .filters input,
.grid-view .filters select {
    min-width: 50px;
}

.hint-block {
    display: block;
    margin-top: 5px;
    color: #999;
}

.error-summary {
    color: #a94442;
    background: #fdf7f7;
    border-left: 3px solid #eed3d7;
    padding: 10px 20px;
    margin: 0 0 15px 0;
}

/* align the logout "link" (button in form) of the navbar */
.navbar form > button.logout {
    padding-top: 7px;
    color: rgba(255, 255, 255, 0.5);
}

@media(max-width:767px) {
    .navbar form > button.logout {
        display:block;
        text-align: left;
        width: 100%;
        padding: 10px 0;
    }
}

.navbar form > button.logout:focus,
.navbar form > button.logout:hover {
    text-decoration: none;
    color: rgba(255, 255, 255, 0.75);
}

.navbar form > button.logout:focus {
    outline: none;
}

/* style breadcrumb widget as in previous bootstrap versions */
.breadcrumb {
    background-color: var(--bs-gray-200);
    border-radius: .25rem;
    padding: .75rem 1rem;
}

.breadcrumb-item > a
{
    text-decoration: none;
}
.site-error{
    height:100%;

}
.notfound .notfound-404 h1 {
  text-align:center;
  font-size: 220px;
  font-weight: 900;
   margin: 0;
  color: var(--col-black);
  text-transform: uppercase;
  letter-spacing: 10px;
}
.notfound h2{
    text-align:center;
}
.notfound p{
    margin-block-start:20px;
    & a{
        color:#000;
        text-decoration: underline;
    }  
}

.scroll{
    position:absolute;
    bottom:0;
    left:50%;
    transform: translateX(-50%);
    @media(max-width:990px) {
        display:none;
    }
 }

/* juicer feed */
[data-feed-id="daveeibachidaseta"]{
    width:100%;
    max-width:1600px;
    & h1{display:none !important;width: 0;overflow:hidden;}
    .j-paginate.juicer-button{display:none;}
}
.juicer-feed a.button{border-bottom: 2px solid var(--col-black);}
.juicer-feed a.button:hover{color:#fff;}

.juicer-feed .j-stacker .j-stack:has([data-source="juicer"]){display:none !important;}