@use '../variables' as *;

* {
  -webkit-font-smoothing: antialiased;
  -webkit-appearance: none;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  outline: none !important;
  outline: 0 !important;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
*::-ms-clear {
  display: none;
}
*::-webkit-inner-spin-button, *::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
html{
	scroll-behavior: smooth;
}
html, body {
  font-size:$font-base;
  line-height: 1.2;
  font-family: Arial, sans-serif;
  font-weight: 400;
  color: $font;
  &.ohns,
  &.m-menu-open {
    overflow: hidden;
    height: 100%;
  }
}
h1, h2, h3, h4, h5, h6 {
	margin-block:0;
}

p{
  font-size: $font-p;
  line-height: 1.7;//1.2;
	margin:0;
  &:empty {
    display: none;
  }
}
strong {
  font-weight: 700;
}
button{
	cursor:pointer;
}

a{
  text-decoration: none;
  -webkit-transition: all 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: all 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  &:focus, &:active, &:visited, &:hover {
    @extend a;
		color:$c-brand;
  }
  &.no-style,&:focus.no-style, &:active.no-style, &:visited.no-style, &:hover.no-style{
    text-decoration: none;
  }// end no-style

  &.minilink, &:focus.minilink, &:active.minilink, &:visited.minilink, &:hover.minilink {
    font-size: .857rem;
  }
  &.more, &:focus.more, &:active.more, &:visited.more, &:hover.more {
    text-transform: uppercase;
    color: $c-black;
    font-weight: bold;
  }

}

ul, ol {
  margin-top: 0;
	list-style-type: none;
	padding-inline-start: 0;
}

//set img as block
img{
  display:block;
}




