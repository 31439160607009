////
/// Grid layout Sediarreda
/// @group Flex SA
////
@use '../variables' as *;

.d-flex{display:flex}
.flex-row{flex-direction:row}
.flex-wrap{flex-wrap:wrap;}
.flex-column{flex-direction: column}
.flex-grow{flex-grow:1}
.vertical-align{
  align-items: center;
  justify-content: center;
}
/** Landscape **/
@media screen and (max-width: 990px) {
  .d-flex-ls{display:flex}
  .flex-ls-wrap{flex-wrap:wrap;}
  .flex-ls-row{flex-direction:row}
  .flex-ls-column{flex-direction: column}
  .flex-ls-grow{flex-grow:1 }
  .vertical-align-ls{
    align-items: center;
    justify-content: center;
  }
}
/** Portrait **/
@media screen and (max-width: 768px) {
  .d-flex-pt{display:flex}
  .flex-pt-wrap{flex-wrap:wrap;}
  .flex-pt-row{flex-direction:row}
  .flex-pt-column{flex-direction: column}
  .flex-pt-grow{flex-grow:1 }
  .vertical-align-pt{
    align-items: center;
    justify-content: center;
  }
}
/** Mobile **/
@media screen and (max-width: 468px) {
  .d-flex-mob{display:flex}
  .flex-mob-wrap{flex-wrap:wrap;}
  .flex-mob-row{flex-direction:row}
  .flex-mob-column{flex-direction: column}
  .flex-mob-grow{flex-grow:1}
  .vertical-align-mob{
    align-items: center;
    justify-content: center;
  }
}
