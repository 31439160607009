////
/// Grid layout Sediarreda
/// @group Griglia SA
////
@use '../variables' as *;


.justify-cont-start  {justify-content: flex-start;}
.justify-cont-end    {justify-content: flex-end;}
.justify-cont-center {justify-content: center;}
.justify-cont-between{justify-content: space-between;}
.justify-cont-around {justify-content: space-around}
/** Landscape **/
@media screen and (max-width: 990px) {
  .justify-cont-ls-start  {justify-content: flex-start;}
  .justify-cont-ls-end    {justify-content: flex-end;}
  .justify-cont-ls-center {justify-content: center;}
  .justify-cont-ls-between{justify-content: space-between;}
  .justify-cont-ls-around {justify-content: space-around}
}
/** Portrait **/
@media screen and (max-width: 768px) {
  .justify-cont-pt-start  {justify-content: flex-start;}
  .justify-cont-pt-end    {justify-content: flex-end;}
  .justify-cont-pt-center {justify-content: center;}
  .justify-cont-pt-between{justify-content: space-between;}
  .justify-cont-pt-around {justify-content: space-around}
}
/** Mobile **/
@media screen and (max-width: 468px) {
  .justify-cont-mob-start  {justify-content: flex-start;}
  .justify-cont-mob-end    {justify-content: flex-end;}
  .justify-cont-mob-center {justify-content: center;}
  .justify-cont-mob-between{justify-content: space-between;}
  .justify-cont-mob-around {justify-content: space-around}
}


/**************************************************
    Align items
    usati su un contenitore flexbox per
    cambiare l'allineamento degli elementi flex
    sull'asse Y (Asse X se flex-direction: column)
*************************************************/
.align-items-start   {align-items: flex-start;}
.align-items-end     {align-items: flex-end;}
.align-items-center  {align-items: center;}
.align-items-baseline{align-items: baseline;}
.align-items-stretch {align-items: stretch;}
/** Landscape **/
@media screen and (max-width: 990px) {
  .align-items-ls-start   {align-items: flex-start;}
  .align-items-ls-end     {align-items: flex-end;}
  .align-items-ls-center  {align-items: center;}
  .align-items-ls-baseline{align-items: baseline;}
  .align-items-ls-stretch {align-items: stretch;}
}
/** Portrait **/
@media screen and (max-width: 768px) {
  .align-items-pt-start   {align-items: flex-start;}
  .align-items-pt-end     {align-items: flex-end;}
  .align-items-pt-center  {align-items: center;}
  .align-items-pt-baseline{align-items: baseline;}
  .align-items-pt-stretch {align-items: stretch;}
}
/** Mobile **/
@media screen and (max-width: 468px) {
  .align-items-mob-start   {align-items: flex-start;}
  .align-items-mob-end     {align-items: flex-end;}
  .align-items-mob-center  {align-items: center;}
  .align-items-mob-baseline{align-items: baseline;}
  .align-items-mob-stretch {align-items: stretch;}
}


/**************************************************
    Align self
    usati su un singolo elemento flexbox per
    cambiare il suo allineamento sull'asse Y
    (Asse X se flex-direction: column)
*************************************************/
.align-self-start   {align-self: flex-start;}
.align-self-end     {align-self: flex-end;}
.align-self-center  {align-self: center;}
.align-self-baseline{align-self: baseline;}
.align-self-stretch {align-self: stretch;}
/** Landscape **/
@media screen and (max-width: 990px) {
  .align-self-ls-start   {align-self: flex-start;}
  .align-self-ls-end     {align-self: flex-end;}
  .align-self-ls-center  {align-self: center;}
  .align-self-ls-baseline{align-self: baseline;}
  .align-self-ls-stretch {align-self: stretch;}
}
/** Portrait **/
@media screen and (max-width: 768px) {
  .align-self-pt-start   {align-self: flex-start;}
  .align-self-pt-end     {align-self: flex-end;}
  .align-self-pt-center  {align-self: center;}
  .align-self-pt-baseline{align-self: baseline;}
  .align-self-pt-stretch {align-self: stretch;}
}
/** Mobile **/
@media screen and (max-width: 468px) {
  .align-self-mob-start   {align-self: flex-start;}
  .align-self-mob-end     {align-self: flex-end;}
  .align-self-mob-center  {align-self: center;}
  .align-self-mob-baseline{align-self: baseline;}
  .align-self-mob-stretch {align-self: stretch;}
}

.align-content-start  {align-content: flex-start}
.align-content-end    {align-content: flex-end}
.align-content-center {align-content: center}
.align-content-around {align-content: space-around}
.align-content-btw    {align-content: space-between}
.align-content-stretch{align-content: stretch}/* default */
/** Landscape **/
@media screen and (max-width: 990px) {
  .align-content-ls-start  {align-content: flex-start}
  .align-content-ls-end    {align-content: flex-end}
  .align-content-ls-center {align-content: center}
  .align-content-ls-around {align-content: space-around}
  .align-content-ls-btw    {align-content: space-between}
  .align-content-ls-stretch{align-content: stretch}/* default */
}
/** Portrait **/
@media screen and (max-width: 768px) {
  .align-content-pt-start  {align-content: flex-start}
  .align-content-pt-end    {align-content: flex-end}
  .align-content-pt-center {align-content: center}
  .align-content-pt-around {align-content: space-around}
  .align-content-pt-btw    {align-content: space-between}
  .align-content-pt-stretch{align-content: stretch}/* default */
}
/** Mobile **/
@media screen and (max-width: 468px) {
  .align-content-mob-start  {align-content: flex-start}
  .align-content-mob-end    {align-content: flex-end}
  .align-content-mob-center {align-content: center}
  .align-content-mob-around {align-content: space-around}
  .align-content-mob-btw    {align-content: space-between}
  .align-content-mob-stretch{align-content: stretch}/* default */
}


/// Creo le classi appoggio per settare un ordine agli elementi
/// grid o flex
@include helperOrder($from:6);